/**
 * DH5VUGENZT   1元
 * DH59MRPTRF   2元
 * DH5DNJ6FV7   3元
 * DH5BNNU4AO   4元
 * DH5URLURZ5   5元
 * DH5CNSDOGT   7元
 */
export default [
  {
    "amount": 1,
    "points": 240,
    "label": "1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },

  {
    "amount": 2,
    "points": 480,
    "label": "2元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 3,
    "points": 720,
    "label": "2元充电券x1;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      },
 {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 4,
    "points": 960,
    "label": "3元充电券x1;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5DNJ6FV7",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 5,
    "points": 1200,
    "label": "3元充电券x1;2元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5DNJ6FV7",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 6,
    "points": 1440,
    "label": "4元充电券x1;2元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 7,
    "points": 1680,
    "label": "4元充电券x1;2元充电券x1;1元充电券x1;",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 8,
    "points": 1800,
    "label": "5元充电券x1;2元充电券x1;1元充电券x1;",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 9,
    "points": 2040,
    "label": "5元充电券x1;2元充电券x1;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 10,
    "points": 2280,
    "label": "5元充电券x1;2元充电券x2;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 2
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 11,
    "points": 2520,
    "label": "5元充电券x1;2元充电券x2;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 2
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 12,
    "points": 2760,
    "label": "5元充电券x1;2元充电券x3;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 3
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 13,
    "points": 3000,
    "label": "5元充电券x1;2元充电券x3;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 3
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 14,
    "points": 3240,
    "label": "5元充电券x1;2元充电券x4;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 4
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 15,
    "points": 3480,
    "label": "5元充电券x1;2元充电券x4;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5URLURZ5",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 4
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 16,
    "points": 3720,
    "label": "7元充电券x1;4元充电券x1;2元充电券x2;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5CNSDOGT",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 2
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 17,
    "points": 3960,
    "label": "7元充电券x1;4元充电券x1;2元充电券x2;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5CNSDOGT",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 2
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 18,
    "points": 4200,
    "label": "7元充电券x1;4元充电券x1;2元充电券x3;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5CNSDOGT",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 3
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  },
  {
    "amount": 19,
    "points": 4440,
    "label": "7元充电券x1;4元充电券x1;2元充电券x3;1元充电券x2",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5CNSDOGT",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 3
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 2
      }
    ]
  },
  {
    "amount": 20,
    "points": 4680,
    "label": "7元充电券x1;4元充电券x1;2元充电券x4;1元充电券x1",
    "checked": false,
    "goodsList": [
      {
        "goodsNo": "DH5CNSDOGT",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH5BNNU4AO",
        "goodsNum": 1
      },
      {
        "goodsNo": "DH59MRPTRF",
        "goodsNum": 4
      },
      {
        "goodsNo": "DH5VUGENZT",
        "goodsNum": 1
      }
    ]
  }
]